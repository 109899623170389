import React, { useCallback } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../app/hooks';
import CustomCheckbox from './CustomCheckBox';
import { MeterConfigurationFilterModal } from './MeterConfigurationFilterModal';
import { selectMeterConfiguration, setCircuitDisplayConfigs } from './meterConfigurationSlice';

export default function MeterConfigurationFilterInline() {
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { circuitDisplayConfigs, hiddenMeterIds } = useAppSelector(selectMeterConfiguration);

  const handleUpdateCircuitDisplayConfig = useCallback(
    (circuitId: string, isChecked: boolean) => {
      const newcircuitDisplayConfigs = circuitDisplayConfigs.map((circuit) =>
        circuit.circuitId === circuitId ? { ...circuit, isHidden: !isChecked } : circuit
      );

      dispatch(setCircuitDisplayConfigs(newcircuitDisplayConfigs));
    },
    [circuitDisplayConfigs, dispatch]
  );

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem
        border={'1px solid'}
        borderColor={'gray.200'}
        _dark={{ borderColor: 'gray.600' }}
        rounded={10}
        overflow={'hidden'}
      >
        <AccordionButton
          data-testid="filter-accordion-toggle"
          background={'gray.100'}
          _dark={{
            background: 'gray.700',
          }}
        >
          <Box as="span" flex="1" textAlign="left">
            Filters
          </Box>
          <Text
            fontWeight={400}
            fontSize={14}
            color="customLinkBlue.500"
            onClick={(e) => {
              e.stopPropagation();
              onOpen();
            }}
            role="button"
            _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
            data-testid="open-filter-modal-button"
            mr={4}
          >
            View All
          </Text>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel pb={4}>
          <Grid
            templateColumns={isMobileViewport ? 'repeat(2, 1fr)' : 'repeat(auto-fit, minmax(185px, 1fr))'}
            rowGap={2}
            columnGap={1}
            my={4}
            data-testid="visible-circuit-filters"
          >
            {circuitDisplayConfigs.map(({ label, isHidden, isSourceCircuit, color, circuitId, meterId }, index) => {
              const isHiddenAndNotSourceCircuit = isHidden && !isSourceCircuit;
              const isCircuitOfAHiddenMeter = hiddenMeterIds.includes(meterId);
              if (isHiddenAndNotSourceCircuit || isCircuitOfAHiddenMeter) return null;

              return (
                <CustomCheckbox
                  key={circuitId}
                  data-testid={`circuit-filter-item-${index}`}
                  isChecked={!isHidden}
                  onChange={(isChecked) => handleUpdateCircuitDisplayConfig(circuitId, isChecked)}
                >
                  <Text bg={color} color="white" minW="fit-content" px={0.5}>
                    {label}
                  </Text>
                </CustomCheckbox>
              );
            })}

            <MeterConfigurationFilterModal isOpen={isOpen} onClose={onClose} />
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
