import {
  LONG_DATA_TYPE_TO_TITLE_MAP,
  SHORT_DATA_TYPE_TO_TITLE_MAP,
} from '../../../user/account/meter-configuration/meter-config-constants';
import {
  LongEnergyData,
  LongPowerDataType,
  LongPowerDataTypeToSeries,
  ShortEnergyData,
  ShortPowerDataType,
  ShortPowerDataTypeToSeries,
} from '../../../user/account/meter-configuration/meter-config-types';
import { CircuitDisplayConfig } from './meterConfigurationSlice';

/**
 * Builds the chart data for short power data.
 * @param combinedEnergyData The short power data to build the chart data from.
 * @param circuitConfigs The circuit configs of the meter.
 * @returns The chart data for short power data.
 */
export function buildCombinedShortChartData(
  combinedEnergyData: ShortEnergyData[][],
  circuitConfigs: CircuitDisplayConfig[]
): ShortPowerDataTypeToSeries {
  // Mapping for each short power data type to its series data
  const chartData: ShortPowerDataTypeToSeries = Object.entries(SHORT_DATA_TYPE_TO_TITLE_MAP).reduce(
    (acc, [key, name]) => {
      acc[key as ShortPowerDataType] = {
        name,
        data: circuitConfigs.map(({ label: name }) => ({ name, data: [] })),
      };
      return acc;
    },
    {} as ShortPowerDataTypeToSeries
  );
  // For each data point, add the data to the correct series
  combinedEnergyData.forEach((energyData, meterIndex) => {
    energyData.forEach((data) => {
      (Object.keys(SHORT_DATA_TYPE_TO_TITLE_MAP) as ShortPowerDataType[]).forEach((key) => {
        // data[key] is a tuple and each of these tuples is indexed by circuit index (0 - 5)
        data[key]?.forEach((value, circuitIndex) => {
          const seriesIndex = circuitIndex + meterIndex * 6;
          const series = chartData[key].data[seriesIndex];
          series.data.push({
            x: data.timestamp * 1000,
            y: value,
          });
        });
      });
    });
  });

  return chartData;
}

/**
 * Builds the chart data for long power data.
 * @param combinedEnergyData The long power data to build the chart data from.
 * @param circuitConfigs The circuit configs of the meter.
 * @returns The chart data for long power data.
 */
export function buildCombinedLongChartData(
  combinedEnergyData: LongEnergyData[][],
  circuitConfigs: CircuitDisplayConfig[]
): LongPowerDataTypeToSeries {
  // Mapping for each long power data type to its series data
  const chartData: LongPowerDataTypeToSeries = Object.entries(LONG_DATA_TYPE_TO_TITLE_MAP).reduce(
    (acc, [key, name]) => {
      acc[key as LongPowerDataType] = {
        name,
        data: circuitConfigs.map(({ label: name }) => ({ name, data: [] })),
      };
      return acc;
    },
    {} as LongPowerDataTypeToSeries
  );

  // For each data point, add the data to the correct series
  combinedEnergyData.forEach((energyData, meterIndex) => {
    energyData.forEach((data) => {
      (Object.keys(LONG_DATA_TYPE_TO_TITLE_MAP) as LongPowerDataType[]).forEach((key) => {
        // data[key] is a tuple and each of these tuples is indexed by circuit index (0 - 5)
        data[key]?.forEach((value, circuitIndex) => {
          const seriesIndex = circuitIndex + meterIndex * 6;
          const series = chartData[key].data[seriesIndex];
          series.data.push({
            x: data.timestamp * 1000,
            y: value,
          });
        });
      });
    });
  });

  return chartData;
}
