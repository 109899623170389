import { useCallback, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { UseFormGetValues, UseFormReset, UseFormTrigger } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChannelData } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { Appliance, ApplianceToSave } from '../../../../api/api-appliance';
import { patch, put } from '../../../../api/api-helpers';
import { useAppDispatch } from '../../../../app/hooks';
import { CONTROLLED_LOAD_ASSIGNMENT_TYPES } from '../../../../common/constants';
import { setHasUnsavedChanges } from '../../../wizard/wizardSlice';
import { selectSite } from '../../siteSlice';
import { GRID_CIRCUIT_TYPES } from '../meter-setup-helpers';
import { addMeter, selectAppliances, selectRawMeters, setAppliances } from '../meterSetupSlice';
import { CTConfigFormData } from './ct-configuration-form-types';
import { TabConfig, TabSource } from './CTConfigurationContainer';

export type UseUpdateCTReturn = {
  grid: (goToTabIndex?: number) => Promise<void>;
  solar: (goToTabIndex?: number) => Promise<void>;
  load: (goToTabIndex?: number) => Promise<void>;
  battery: (goToTabIndex?: number) => Promise<void>;
  hybrid: (goToTabIndex?: number) => Promise<void>;
  evCharger: (goToTabIndex?: number) => Promise<void>;
};

/**
 * This function is reponsible for generating CTA functions for each tab.
 *
 * @param getValues - from react-hook-form
 * @param trigger - from react-hook-form
 * @param reset - from react-hook-form
 * @param onChangeTab - function that changes the tab views
 * @param handleIsUpdatingCTs - function that updates loading state
 * @param setOpenThreePhaseWarningDrawer - function that opens the info drawer
 * @param tabConfig - object with current state of tabs in the view
 * @returns functions to update each tab values
 */
const useUpdateCTFunctions = (
  getValues: UseFormGetValues<CTConfigFormData>,
  trigger: UseFormTrigger<CTConfigFormData>,
  reset: UseFormReset<CTConfigFormData>,
  onChangeTab: (index: number, gotoClickedTabIndex?: boolean) => void,
  handleIsUpdatingCTs: (value: boolean) => void,
  setOpenThreePhaseWarningDrawer: (value: boolean) => void,
  tabConfig: TabConfig[]
): UseUpdateCTReturn => {
  const rawMeters = useSelector(selectRawMeters);
  const site = useSelector(selectSite);
  const appliances = useSelector(selectAppliances);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const getNextTabIndex = useCallback(
    (tabSource: TabSource) => {
      const currentTabIndex = tabConfig.findIndex((tab) => tab.key === tabSource);
      return currentTabIndex + 1;
    },
    [tabConfig]
  );

  const handleUpdateGridCTs = useCallback(
    async (goToTabIndex?: number) => {
      const grid = getValues().grid;
      const isThreePhaseSite = site.electrical_config === '3ph';
      const hasLessThanThreeGridCTs = grid.length !== 3;

      if (isThreePhaseSite && hasLessThanThreeGridCTs) return setOpenThreePhaseWarningDrawer(true);
      onChangeTab(goToTabIndex !== undefined ? goToTabIndex : 1);
    },
    [getValues, onChangeTab, setOpenThreePhaseWarningDrawer, site.electrical_config]
  );

  const handleUpdateSolarCTs = useCallback(
    async (goToTabIndex?: number) => {
      onChangeTab(goToTabIndex !== undefined ? goToTabIndex : getNextTabIndex('solar'));
    },
    [getNextTabIndex, onChangeTab]
  );

  const handleUpdateBatteryCTs = useCallback(
    async (goToTabIndex?: number) => {
      onChangeTab(goToTabIndex !== undefined ? goToTabIndex : getNextTabIndex('battery'));
    },
    [getNextTabIndex, onChangeTab]
  );

  const handleUpdateHybridBatteryCTs = useCallback(
    async (goToTabIndex?: number) => {
      onChangeTab(goToTabIndex !== undefined ? goToTabIndex : getNextTabIndex('hybrid'));
    },
    [getNextTabIndex, onChangeTab]
  );

  const handleUpdateEVChargerCTS = useCallback(
    async (goToTabIndex?: number) => {
      onChangeTab(goToTabIndex !== undefined ? goToTabIndex : getNextTabIndex('evCharger'));
    },
    [getNextTabIndex, onChangeTab]
  );

  const handleUpdateLoadCTs = useCallback(
    async (goToTabIndex?: number) => {
      const isValid = await trigger();
      if (!isValid) return;

      handleIsUpdatingCTs(true);

      const {
        load: loadAppliances,
        grid: allAssignedGridCircuits,
        solar: solarAppliances,
        battery: batteryAppliances,
        hybrid: hybridAppliances,
        evCharger: evChargerAppliances,
      } = getValues();

      const allAssignedLoadCircuits = loadAppliances.reduce<ChannelData[]>(
        (allCircuits, { loadName, loadType, isControlledLoad, circuits }) => [
          ...allCircuits,
          ...circuits.map((circuits) => ({
            ...circuits,
            circuit_name: loadName,
            clipsal_monitors: loadType,
            controlled_load_yn: (isControlledLoad ? 'Y' : 'N') as ChannelData['controlled_load_yn'],
          })),
        ],
        []
      );

      const allAssignedSolarCircuits = solarAppliances.reduce<ChannelData[]>(
        (allCircuits, { circuits }) => [...allCircuits, ...circuits],
        []
      );

      const allAssignedBatteryCircuits = batteryAppliances.reduce<ChannelData[]>(
        (allCircuits, { circuits }) => [...allCircuits, ...circuits],
        []
      );
      const allAssignedHybridCircuits = Object.values(hybridAppliances).flat();

      const allAssignedEVChargerCircuits = evChargerAppliances.reduce<ChannelData[]>(
        (allCircuits, { loadName, circuits }) => [
          ...allCircuits,
          ...circuits.map((circuits) => ({
            ...circuits,
            circuit_name: loadName,
          })),
        ],
        []
      );

      // Get all IDs for meters from loadAppliances, solarAppliances & batteryAppliances
      const uniqueMeterIds = [
        ...loadAppliances,
        ...solarAppliances,
        ...batteryAppliances,
        ...evChargerAppliances,
      ].reduce<string[]>((acc, loadAppliance) => {
        loadAppliance.circuits.forEach((circuit) => {
          const meterId = circuit.ww_circuit_id.split('_')[0];
          if (!acc.includes(meterId)) acc.push(meterId);
        });
        return acc;
      }, []);

      // Also grab unique meter IDs from hybrid circuits and grid circuits
      const hybridAndGridCircuits = [...allAssignedHybridCircuits, ...allAssignedGridCircuits];
      hybridAndGridCircuits.forEach((circuit) => {
        const meterId = circuit.ww_circuit_id.split('_')[0];
        if (!uniqueMeterIds.includes(meterId)) uniqueMeterIds.push(meterId);
      });

      // Update the meter objects in Redux
      const updatedMeters = uniqueMeterIds.map((id) => {
        const meter = cloneDeep(rawMeters[id]);

        meter.circuits.forEach((circuit) => {
          // Remove existing CTs from this meter
          circuit.circuit_name = 'Not Set';
          circuit.clipsal_monitors = 'not_set';
        });

        allAssignedGridCircuits.forEach((selectedGridCT) => {
          const meterCT = meter.circuits.find((c) => c.ww_circuit_id === selectedGridCT.ww_circuit_id);
          if (meterCT) {
            meterCT.circuit_name = selectedGridCT.circuit_name;
            meterCT.clipsal_monitors = site.metering_configuration === 'NET' ? 'ac_load_net' : 'ac_load';
          }
        });

        [...allAssignedLoadCircuits, ...allAssignedEVChargerCircuits].forEach((selectedLoadCT) => {
          const meterCT = meter.circuits.find((c) => c.ww_circuit_id === selectedLoadCT.ww_circuit_id);

          if (meterCT) {
            meterCT.circuit_name = selectedLoadCT.circuit_name;
            meterCT.clipsal_monitors = selectedLoadCT.clipsal_monitors;
            meterCT.controlled_load_yn = CONTROLLED_LOAD_ASSIGNMENT_TYPES.includes(selectedLoadCT.clipsal_monitors)
              ? selectedLoadCT.controlled_load_yn
              : 'N';
          }
        });

        allAssignedSolarCircuits.forEach((selectedSolarCT) => {
          const meterCT = meter.circuits.find((c) => c.ww_circuit_id === selectedSolarCT.ww_circuit_id);
          if (meterCT) {
            meterCT.circuit_name = selectedSolarCT.circuit_name;
            meterCT.clipsal_monitors = site.metering_configuration === 'NET' ? 'pv_site_net' : 'pv_site';
          }
        });

        allAssignedBatteryCircuits.forEach((selectedBatteryCT) => {
          const meterCT = meter.circuits.find((c) => c.ww_circuit_id === selectedBatteryCT.ww_circuit_id);
          if (meterCT) {
            meterCT.circuit_name = selectedBatteryCT.circuit_name;
            meterCT.clipsal_monitors = 'battery_storage';
          }
        });

        allAssignedHybridCircuits.forEach((selectedHybridCT) => {
          const meterCT = meter.circuits.find((c) => c.ww_circuit_id === selectedHybridCT.ww_circuit_id);
          if (meterCT) {
            meterCT.circuit_name = selectedHybridCT.circuit_name;
            meterCT.clipsal_monitors = selectedHybridCT.clipsal_monitors;
          }
        });

        dispatch(addMeter(meter));

        return meter;
      });

      // Updating meter first to ensure that the CTs are updated before the appliances
      // This is to ensure that the appliances are not assigned to CTs that are not assigned to the meter
      // Backend will throw validation errors if meters are not updated first
      try {
        // Update each meter respectively
        await Promise.all(
          updatedMeters.map((m) => patch(`/fleet/wattwatchers/installed_meters/${m.installed_device_id}`, m))
        );
      } catch (e) {
        console.error(e);

        toast({
          title: 'One or more meters were unable to save.',
          status: 'error',
          isClosable: true,
        });
      }

      try {
        let gridApplianceToSave = cloneDeep(
          (appliances as ApplianceToSave[]).find((a) => GRID_CIRCUIT_TYPES.includes(a.appliance_type))
        );
        const selectedGridCTIDs = allAssignedGridCircuits.map((circuit) => circuit.clipsal_circuit_id);

        if (gridApplianceToSave) {
          gridApplianceToSave.circuits = selectedGridCTIDs;
        } else {
          gridApplianceToSave = {
            appliance_id: null,
            appliance_name: 'Grid',
            appliance_type: site.metering_configuration === 'NET' ? 'ac_load_net' : 'ac_load',
            circuits: selectedGridCTIDs,
            control_device_id: null,
          };
        }

        const solarAppliancesToSave = solarAppliances.map<ApplianceToSave>((a, i) => {
          return {
            appliance_id: a.applianceId ?? null,
            appliance_name: `Solar ${i + 1}`,
            appliance_type: site.metering_configuration === 'NET' ? 'pv_site_net' : 'pv_site',
            circuits: a.circuits.map((c) => c.clipsal_circuit_id),
            control_device_id: null,
          };
        });

        const batteryAppliancesToSave = batteryAppliances.map<ApplianceToSave>((a, i) => {
          return {
            appliance_id: a.applianceId ?? null,
            appliance_name: `Battery ${i + 1}`,
            appliance_type: 'battery_storage',
            circuits: a.circuits.map((c) => c.clipsal_circuit_id),
            control_device_id: null,
          };
        });

        const loadAppliancesToSave = loadAppliances.map<ApplianceToSave>((a) => {
          return {
            appliance_id: a.applianceId ?? null,
            appliance_name: a.loadName,
            appliance_type: a.loadType,
            circuits: a.circuits.map((c) => c.clipsal_circuit_id),
            control_device_id: a.controlDeviceRowId,
          };
        });

        const evChargerAppliancesToSave = evChargerAppliances.map<ApplianceToSave>((a) => {
          return {
            appliance_id: a.applianceId ?? null,
            appliance_name: a.loadName,
            appliance_type: 'load_ev_charger',
            circuits: a.circuits.map((c) => c.clipsal_circuit_id),
            control_device_id: a.controlDeviceRowId,
          };
        });

        // Assuming there is exactly one hybrid inverter in the site
        const hybridInverterApplianceToSave = (appliances as ApplianceToSave[]).find(
          (a) => a.appliance_type === 'hybrid_inverter'
        );

        // Assuming there is exactly one backup circuit in the site
        const backupCircuitApplianceToSave = cloneDeep(
          (appliances as ApplianceToSave[]).find((a) => a.appliance_type === 'backup_circuit')
        );

        const hybridAppliancesToSave = (Object.keys(hybridAppliances) as ('inverter' | 'backup')[]).reduce<
          ApplianceToSave[]
        >((acc, circuitType) => {
          const isHybridInverter = circuitType === 'inverter';
          const applianceName = isHybridInverter ? 'Hybrid Inverter' : 'Backup Circuit';
          const applianceType = isHybridInverter ? 'hybrid_inverter' : 'backup_circuit';
          const applianceToSave = isHybridInverter ? hybridInverterApplianceToSave : backupCircuitApplianceToSave;
          const circuits = hybridAppliances[circuitType].map((c) => c.clipsal_circuit_id);
          if (circuits.length)
            acc.push({
              appliance_id: applianceToSave?.appliance_id ?? null,
              appliance_name: applianceName,
              appliance_type: applianceType,
              circuits,
              control_device_id: null,
            });

          return acc;
        }, [] as ApplianceToSave[]);

        const appliancesToSave = [
          ...solarAppliancesToSave,
          ...batteryAppliancesToSave,
          ...loadAppliancesToSave,
          ...evChargerAppliancesToSave,
          ...hybridAppliancesToSave,
          gridApplianceToSave,
        ];
        const savedApplianceData = await put<Appliance[]>(
          `/fleet/sites/${site.clipsal_solar_id}/appliances`,
          appliancesToSave
        );
        dispatch(setAppliances(savedApplianceData));

        toast({
          title: 'All CTs configured successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (e) {
        console.error(e);

        toast({
          title: 'Unable to save CTs.',
          status: 'error',
          isClosable: true,
        });
      }

      await dispatch(setHasUnsavedChanges(false));

      // resets touched and isDirty properties and avoid removing values
      reset(undefined, { keepValues: true });
      handleIsUpdatingCTs(false);

      if (goToTabIndex !== undefined) {
        onChangeTab(goToTabIndex);
      } else navigate(`/site/${site.clipsal_solar_id}/meter_setup/meters`);
    },
    [
      appliances,
      dispatch,
      getValues,
      handleIsUpdatingCTs,
      onChangeTab,
      rawMeters,
      reset,
      site.clipsal_solar_id,
      site.metering_configuration,
      toast,
      trigger,
    ]
  );

  return useMemo(
    () => ({
      grid: handleUpdateGridCTs,
      solar: handleUpdateSolarCTs,
      battery: handleUpdateBatteryCTs,
      load: handleUpdateLoadCTs,
      hybrid: handleUpdateHybridBatteryCTs,
      evCharger: handleUpdateEVChargerCTS,
    }),
    [
      handleUpdateBatteryCTs,
      handleUpdateGridCTs,
      handleUpdateHybridBatteryCTs,
      handleUpdateLoadCTs,
      handleUpdateSolarCTs,
      handleUpdateEVChargerCTS,
    ]
  );
};

export default useUpdateCTFunctions;
