import React, { useEffect, useState } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { Appliance } from '../../../api/api-appliance';
import { InstalledDevice } from '../../../api/api-device';
import { get, patch } from '../../../api/api-helpers';
import { useAppDispatch } from '../../../app/hooks';
import { useSiteRouteChangeContext } from '../../../context/context-exports';
import { setHasUnsavedChanges } from '../../wizard/wizardSlice';
import { WizardSubRouteCardWrapper } from '../../wizard/WizardSubRouteCardWrapper';
import { SiteRouteChangeContextProps } from '../Site';
import { selectSite } from '../siteSlice';
import {
  fetchAssignments,
  selectAssignments,
  setBatteries,
  setEVChargers,
  setInverters,
} from '../system-details/systemDetailsSlice';
import { checkMeterPhasing } from './meter-phasing-helpers';
import { addMeters, selectRawMeters, setAppliances } from './meterSetupSlice';

export default function MeterSetup() {
  const { onMoveForward } = useSiteRouteChangeContext();
  const isLoaded = useFetchMeters();

  return (
    <>
      {!isLoaded ? (
        <MeterSetupCenteredLoader />
      ) : (
        <Box>
          <Outlet context={{ onMoveForward } satisfies SiteRouteChangeContextProps} />
        </Box>
      )}
    </>
  );
}

function MeterSetupCenteredLoader() {
  const { pathname } = useLocation();
  const isOnMeterSetupSubRoute = /\/meter_setup\/configure\//.test(pathname);

  // Wizard Stepper is hidden on meter setup sub routes, so we need to override padding to the top of the wizard
  const wizardCardProps = isOnMeterSetupSubRoute
    ? {
        containerProps: { pt: 0, mt: 0 },
        p: [0],
      }
    : undefined;

  return (
    <WizardSubRouteCardWrapper {...wizardCardProps}>
      <CenteredLoader minH={300} />
    </WizardSubRouteCardWrapper>
  );
}

/**
 * Custom hook which fetches meters for the site and updates the redux store.
 *
 * @returns Boolean specifying whether all data has loaded and the page is ready for user interaction.
 *
 */
function useFetchMeters() {
  const [isLoaded, setLoaded] = useState(false);
  const [hasFetchedOtherDevices, setHasFetchedOtherDevices] = useState(false);
  const dispatch = useAppDispatch();
  const site = useSelector(selectSite);
  const assignments = useSelector(selectAssignments);
  const toast = useToast();
  const meters = useSelector(selectRawMeters);

  useEffect(() => {
    async function fetchAPI() {
      dispatch(setHasUnsavedChanges(false));

      try {
        const meterDevices = await get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/meters`);
        if (meterDevices.length) {
          // Get all inverters, batteries, EV chargers, appliances and meter data
          const [inverters, batteries, evChargers, appliances, ...wattwatchersMeterData] = await Promise.all([
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/inverters`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/batteries`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/ev_chargers`),
            get<Appliance[]>(`/fleet/sites/${site.clipsal_solar_id}/appliances`),
            ...meterDevices.map((meter) =>
              get<WattwatchersMeter>(`/fleet/wattwatchers/installed_meters/${meter.row_id}`)
            ),
          ]);
          setHasFetchedOtherDevices(true);

          // update phasing in meters if required as it needs to match site phasing
          const { isCorrectlyPhased, updatedMeters } = checkMeterPhasing(site.electrical_config, wattwatchersMeterData);
          if (!isCorrectlyPhased) {
            // Update each meter respectively
            const updatedMeterData = await Promise.all(
              updatedMeters.map<Promise<WattwatchersMeter>>((m) =>
                patch(`/fleet/wattwatchers/installed_meters/${m.installed_device_id}`, m)
              )
            );
            toast({
              title: `Meter voltage references updated!`,
              description: 'Circuit voltage references has been updated as per site electrical configuration.',
              status: 'info',
              isClosable: true,
            });
            dispatch(addMeters(updatedMeterData));
          } else {
            dispatch(addMeters(wattwatchersMeterData));
          }

          // update data in redux
          dispatch(setAppliances(appliances));
          dispatch(setInverters(inverters));
          dispatch(setBatteries(batteries));
          dispatch(setEVChargers(evChargers));

          // Cache circuit type options (aka assignments or monitor types)
          if (!assignments.length) await dispatch(fetchAssignments());

          // fetch inverters and batteries if not fetched yet
        } else if (!hasFetchedOtherDevices) {
          // Get all inverters, batteries and EV chargers
          const [inverters, batteries, evChargers] = await Promise.all([
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/inverters`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/batteries`),
            get<InstalledDevice[]>(`/fleet/sites/${site.clipsal_solar_id}/ev_chargers`),
          ]);
          dispatch(setInverters(inverters));
          dispatch(setBatteries(batteries));
          dispatch(setEVChargers(evChargers));
          setHasFetchedOtherDevices(true);
        }
      } catch (e) {
        console.error(e);
        toast({
          title: 'Unable to load meter data.',
          description: 'Note that Solar Analytics meters will not work with this tool.',
          status: 'error',
          isClosable: true,
        });
      }
      setLoaded(true);
    }

    // As this component is wrapped with React Transition group, it is cloned in each route changes as
    // it is required for sliding animation. Hence this is rerendered on every route changes.
    // Thus we have to use redux values to determine if we need to fetch API
    if (!isLoaded && !Object.keys(meters).length) {
      fetchAPI();
    } else {
      if (Object.keys(meters).length && !isLoaded) setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, toast, site.clipsal_solar_id]);

  return isLoaded;
}
