import { useEffect, useMemo } from 'react';
import { useColorModeValue, useTheme } from '@chakra-ui/react';
import { toZonedTime } from 'date-fns-tz';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';
import { formatDate, formatTime } from 'clipsal-cortex-utils/src/formatting/formatting';

import { useAppSelector } from '../../../../app/hooks';
import { COMMON_CHART_DATETIME_FORMATS } from '../../../../common/constants';
import { DATA_TYPE_TO_VALUE_SUFFIX } from '../../../user/account/meter-configuration/meter-config-constants';
import {
  useCombinedLongEnergyData,
  useCombinedShortEnergyData,
} from '../../../user/account/meter-configuration/meterConfigApi';
import { selectSite } from '../../siteSlice';
import { buildCombinedLongChartData, buildCombinedShortChartData } from './meter-config-helpers';
import { selectMeterConfiguration, setIsLoaded } from './meterConfigurationSlice';

export default function useMeterChartOptions() {
  const { timezone } = useAppSelector(selectSite);
  const {
    meterChartConfig: { selectedInterval, selectedShortPowerDataType, selectedLongPowerDataType, selectedDate },
    circuitDisplayConfigs,
    memoizedRawMeters,
    isLoaded,
  } = useAppSelector(selectMeterConfiguration);
  const meterIds = useMemo(
    () => Object.values(memoizedRawMeters).map((m) => m.installed_device_id),
    [memoizedRawMeters]
  ) as string[];

  const isShortEnergySelected = selectedInterval === 'Short Energy';
  const {
    data: longEnergyData,
    refetch,
    isFetching: isFetchingLongEnergy,
    isLoading: isLoadingLongEnergy,
  } = useCombinedLongEnergyData(
    meterIds,
    {
      reading_date: formatDate(selectedDate),
      timezone: timezone,
    },
    isShortEnergySelected
  );

  const {
    data: shortEnergyData,
    isLoading: isLoadingShortEnergy,
    isFetching: isFetchingShortEnergy,
  } = useCombinedShortEnergyData(meterIds, !isShortEnergySelected);

  const isLoading = isLoadingLongEnergy || isLoadingShortEnergy;
  const isFetching = isFetchingLongEnergy || isFetchingShortEnergy;

  const dispatch = useDispatch();

  // Update the state when the raw meters have been synced and chart data has been fetched
  useEffect(() => {
    if (Object.values(memoizedRawMeters).length && !isLoaded && !isLoading) dispatch(setIsLoaded(true));
  }, [memoizedRawMeters, isLoading]);

  const theme = useTheme();
  const chartLabelColor = useColorModeValue(theme.colors.gray[500], theme.colors.gray[400]);

  const { seriesData, chartOptions, latestCommunicatedTime } = useMemo(() => {
    const longChartData = buildCombinedLongChartData(longEnergyData, circuitDisplayConfigs);

    const shortChartData = buildCombinedShortChartData(shortEnergyData, circuitDisplayConfigs);

    const seriesData = cloneDeep(
      isShortEnergySelected ? shortChartData[selectedShortPowerDataType] : longChartData[selectedLongPowerDataType]
    );

    const chartOptions = {
      title: { text: '' },
      legend: { enabled: false },
      yAxis: {
        title: { text: '' },
        labels: {
          align: 'left',
          x: 5,
          y: -3,
          style: { color: chartLabelColor },
          formatter: function () {
            return (
              this.value +
              ` ${
                DATA_TYPE_TO_VALUE_SUFFIX[
                  isShortEnergySelected ? selectedShortPowerDataType : selectedLongPowerDataType
                ]
              }`
            );
          },
        },
        showLastLabel: false,
        gridLineDashStyle: 'longdash',
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
        labels: {
          style: { color: chartLabelColor },
        },
      },
      time: {
        moment,
        timezone,
      },
      tooltip: {
        animation: false,
        shared: true,
        dateTimeLabelFormats: COMMON_CHART_DATETIME_FORMATS,
      },
      series: seriesData.data.map((series, index) => {
        return {
          ...series,
          visible: !circuitDisplayConfigs[index].isHidden,
        };
      }),
      colors: COLOURS,
      chart: {
        zoomType: 'x',
        height: '300px',
        backgroundColor: 'transparent',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
    };

    let latestCommunicatedTime = 'N/A';
    if (seriesData.data.length) {
      // Find the first series with data
      const indexOfSeriesWithData = seriesData.data.findIndex((d) => d.data.length);
      const seriesIndex = indexOfSeriesWithData === -1 ? 0 : indexOfSeriesWithData;
      const latestTime = seriesData.data[seriesIndex].data[seriesData.data[seriesIndex].data.length - 1]?.x;
      if (latestTime) latestCommunicatedTime = formatTime(toZonedTime(new Date(latestTime), timezone));
    }

    return { seriesData, chartOptions, latestCommunicatedTime };
  }, [
    selectedLongPowerDataType,
    selectedShortPowerDataType,
    selectedInterval,
    timezone,
    shortEnergyData,
    longEnergyData,
    chartLabelColor,
    circuitDisplayConfigs,
  ]);

  return {
    seriesData,
    chartOptions,
    latestCommunicatedTime,
    isLoading: isLoading || !isLoaded,
    isFetching,
    refetch,
    isShortEnergySelected,
  };
}
