import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactGA from 'react-ga4';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';

import { IS_PRODUCTION_BUILD, SUPPORT_EMAIL, SUPPORT_PHONE } from '../common/constants';
import { Children } from '../common/types/types';
import { SupportDrawerContext } from './context-exports';

const SupportDrawerContextProvider = ({ children }: Children) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { background, drawerBackground } = useColorModeValue(
    { background: 'white', drawerBackground: 'dusk005.500' },
    { background: 'gray.900', drawerBackground: 'gray.700' }
  );

  const onOpenSupportDrawer = () => {
    setOpenDrawer(true);

    // send Event to GA and Amplitude, if we're in prod.
    if (IS_PRODUCTION_BUILD) {
      ReactGA.event({
        category: 'Support',
        action: 'Clicked Help Button',
        label: 'Support Drawer Open Button',
        value: 1,
      });

      amplitude.track('Help Button Clicked', {
        result: 'Opens Support Drawer',
      });
    }
  };

  const value = {
    onOpenSupportDrawer,
  };

  return (
    <SupportDrawerContext.Provider value={value}>
      <Drawer isOpen={openDrawer} placement="bottom" onClose={() => setOpenDrawer(false)}>
        <DrawerOverlay />
        <DrawerContent bg={drawerBackground} data-testid="support-drawer">
          <DrawerCloseButton data-testid="support-drawer-close-btn" />
          <DrawerHeader>Contact Details</DrawerHeader>

          <DrawerBody>
            <Flex direction="column" data-testid="support-drawer">
              <Link
                bg={background}
                variant="solid"
                justifyContent="flex-start"
                py={4}
                px={4}
                rounded={5}
                href={`tel:${SUPPORT_PHONE}`}
              >
                <Flex justify="space-between" align="center">
                  <Flex align="center">
                    <Icon as={HiOutlinePhone} w={5} h={5} />
                    <Text ml={2} fontWeight="500">
                      Phone number
                    </Text>
                  </Flex>
                  <Text color="fixedCostGrey.500">{SUPPORT_PHONE}</Text>
                </Flex>
              </Link>
              <Link
                my={4}
                bg={background}
                variant="solid"
                justifyContent="flex-start"
                py={4}
                px={4}
                rounded={5}
                href={`mailto:${SUPPORT_EMAIL}?subject=Clipsal Cortex Fleet App Issues`}
              >
                <Flex justify="space-between" align="center">
                  <Flex align="center">
                    <Icon as={HiOutlineMail} w={5} h={5} />
                    <Text ml={2} fontWeight="500">
                      Email
                    </Text>
                  </Flex>
                  <Text color="fixedCostGrey.500">{SUPPORT_EMAIL}</Text>
                </Flex>
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {children}
    </SupportDrawerContext.Provider>
  );
};

export default SupportDrawerContextProvider;
