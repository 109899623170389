import React, { useRef } from 'react';
import { Box, Center, Skeleton } from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

import { useAppSelector } from '../../../../app/hooks';
import MeterConfigurationChartSettings from './MeterConfigurationChartSettings';
import { selectMeterConfiguration } from './meterConfigurationSlice';
import useMeterChartOptions from './use-meter-chart-options';

export default function MeterConfigurationChart() {
  const chartRef = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  } | null>(null);
  const {
    meterChartConfig: { selectedInterval },
    circuitDisplayConfigs,
  } = useAppSelector(selectMeterConfiguration);
  const { isLoading, isFetching, refetch, seriesData, chartOptions, latestCommunicatedTime } = useMeterChartOptions();

  const getChartContents = () => {
    // if it is loaded and there is no data, show message
    const hasSeriesData = seriesData.data.some(({ data }) => data.length);
    if (!isLoading && !hasSeriesData)
      return (
        <Center w="100%" height={200}>
          There is no data for this device on this day
        </Center>
      );

    // if no filters are applied, show message
    if (!isLoading && circuitDisplayConfigs.every(({ isHidden }) => isHidden))
      return (
        <Center w="100%" h={200}>
          Use filters to show circuits
        </Center>
      );

    return (
      <Skeleton isLoaded={!isLoading} minHeight={'300px'}>
        <Box style={{ margin: '1rem 0' }} data-testid={`chart-${selectedInterval}`} minH={300}>
          <Chart ref={chartRef} highcharts={Highcharts} options={chartOptions} />
        </Box>
      </Skeleton>
    );
  };

  return (
    <>
      <MeterConfigurationChartSettings
        isFetching={isFetching}
        onRefetch={refetch}
        latestCommunicatedTime={latestCommunicatedTime}
      />

      {getChartContents()}
    </>
  );
}
