import React from 'react';

import { Polarity } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { PolarityNormalIcon, PolarityReverseIcon } from '../../../../styles/custom-icons';

export const POLARITY_TYPES: { value: Polarity; label: JSX.Element }[] = [
  { value: 'normal', label: <PolarityNormalIcon h={5} /> },
  { value: 'reverse', label: <PolarityReverseIcon h={5} /> },
];
