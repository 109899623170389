import React, { useEffect } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { selectSite } from '../../siteSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import CircuitCardsListView from './CircuitCardsListView';
import MeterConfigurationChart from './MeterConfigurationChart';
import MeterConfigurationFilterInline from './MeterConfigurationFilterInline';
import { resetMeterConfigurationState } from './meterConfigurationSlice';
import SyncMeterState from './SyncMeterState';

export default function MeterSetupConfiguration() {
  const { clipsal_solar_id: siteId, isLoaded } = useSelector(selectSite);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset the state when the component is unmounted
    return () => {
      dispatch(resetMeterConfigurationState());
    };
  }, []);

  return (
    <Box w="100%" minHeight={COMPONENT_MIN_HEIGHT} data-testid="meter-configuration-form">
      <SubRouteTopNav onGoBack={() => navigate(`/site/${siteId}/meter_setup/meters`)} title="Meter Configuration" />

      {/* This component is used to abosrb re-renders as meter data is pulled every 5s */}
      <SyncMeterState />

      <MeterConfigurationChart />

      <Skeleton isLoaded={isLoaded} my={4}>
        <MeterConfigurationFilterInline />
      </Skeleton>

      <Skeleton isLoaded={isLoaded} minHeight={'300px'} mb={4}>
        <CircuitCardsListView />
      </Skeleton>
    </Box>
  );
}
