import { VoltageReference } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { Appliance } from '../../../api/api-appliance';
import { InstalledDevice } from '../../../api/api-device';
import { ElectricalConfiguration } from '../../../api/api-site';
import { VOLTAGE_REFERENCE_TYPES } from '../../../common/constants';
import { SelectOption } from './../../../common/types/types';
import { MeterData } from './meter-setup-form-types';

// Only display the voltage reference letter here. Mapped as a const to avoid necessity to memoize.
export const DISPLAYED_VOLTAGE_REF_TYPES: Record<ElectricalConfiguration, SelectOption<VoltageReference>[]> = {
  '1ph': VOLTAGE_REFERENCE_TYPES.slice(0, 1).map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
  '2ph': VOLTAGE_REFERENCE_TYPES.slice(0, 2).map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
  '3ph': VOLTAGE_REFERENCE_TYPES.slice(0, 3).map((p) => ({ ...p, label: p.label[p.label.length - 1] })),
};

export const getEmptyMeterTemplate = (label = ''): MeterData => ({
  meterId: null,
  isLoaded: false,
  serialNumber: '',
  model: '',
  label,
});

// @TODO: maybe find a more relevant place to put these?
export const GRID_CIRCUIT_TYPES = ['ac_load_net', 'ac_load'];
export const SOLAR_CIRCUIT_TYPES = ['pv_site_net', 'pv_site'];
export const HYBRID_CIRCUIT_TYPES = ['hybrid_inverter', 'backup_circuit'];
export const BATTERY_CIRCUIT_TYPES = ['battery_storage'];

export const NON_LOAD_CIRCUIT_TYPES = [
  ...GRID_CIRCUIT_TYPES,
  ...SOLAR_CIRCUIT_TYPES,
  ...BATTERY_CIRCUIT_TYPES,
  ...HYBRID_CIRCUIT_TYPES,
  'consumption',
];

export function checkIfAppliancesAreAssigned(
  appliances: Appliance[],
  inverters: InstalledDevice[],
  batteries: InstalledDevice[]
) {
  if (!appliances?.length) return false;

  let hasAcCoupledInverter = false,
    hasDcCoupledInverter = false,
    totalDCCoupledInverters = 0;
  const hasBattery = !!batteries.length;

  inverters.forEach((inverter) => {
    // two conditions as both type of inverters can co-exist
    hasDcCoupledInverter = hasDcCoupledInverter || !!inverter.is_hybrid_inverter;
    hasAcCoupledInverter = hasAcCoupledInverter || !inverter.is_hybrid_inverter;
    if (inverter.is_hybrid_inverter) totalDCCoupledInverters += 1;
  });

  let hasGridAppliance = false,
    hasSolarAppliance = false,
    hasBatteryAppliance = false,
    hasHybridAppliance = false;

  appliances.forEach((appliance) => {
    if (!appliance.circuits.length) return;

    // only update when flags are false
    hasGridAppliance = hasGridAppliance || GRID_CIRCUIT_TYPES.includes(appliance.appliance_type);
    hasSolarAppliance = hasSolarAppliance || SOLAR_CIRCUIT_TYPES.includes(appliance.appliance_type);
    hasBatteryAppliance = hasBatteryAppliance || appliance.appliance_type === 'battery_storage';
    hasHybridAppliance = hasHybridAppliance || appliance.appliance_type === 'hybrid_inverter';
  });

  // conditionally checking if appliances are properly assigned
  if (hasAcCoupledInverter && !hasSolarAppliance) return false;
  if (hasDcCoupledInverter && !hasHybridAppliance) return false;
  if (hasBattery && batteries.length > totalDCCoupledInverters && !hasBatteryAppliance) return false;

  // Sites might not have any loads (e.g. consumption only, or 3 phase sites monitoring solar on 3 CTs and grid on 3
  // with a single meter. This means all 6 CTs are assigned to other source types.
  return hasGridAppliance;
}
