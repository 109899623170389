import React, { PropsWithChildren } from 'react';
import { useBlocker } from 'react-router-dom';

import { BlockerContext, BlockerContextProps } from './context-exports';

interface Props {
  value: BlockerContextProps;
}

export const BlockerContextProvider = ({ value, children }: PropsWithChildren<Props>) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname);

  return <BlockerContext.Provider value={{ ...value, blocker }}>{children}</BlockerContext.Provider>;
};
