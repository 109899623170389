import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../app/hooks';
import { selectMeterConfiguration, setCircuitDisplayConfigs, setHiddenMeterIds } from './meterConfigurationSlice';

export default function useMeterFilterConfiguration() {
  const { circuitDisplayConfigs, hiddenMeterIds, memoizedRawMeters } = useSelector(selectMeterConfiguration);
  const dispatch = useAppDispatch();

  const { availableCircuits, sourceCircuits, loadCircuits, isAllSourceCircuitsSelected, isAllLoadCircuitsSelected } =
    useMemo(() => {
      const availableCircuits = circuitDisplayConfigs.filter(({ meterId }) => !hiddenMeterIds.includes(meterId));
      const sourceCircuits = availableCircuits.filter(({ isSourceCircuit }) => isSourceCircuit);
      const loadCircuits = availableCircuits.filter(({ isSourceCircuit }) => !isSourceCircuit);
      const isAllSourceCircuitsSelected = sourceCircuits.every(({ isHidden }) => !isHidden);
      const isAllLoadCircuitsSelected = loadCircuits.every(({ isHidden }) => !isHidden);
      return {
        availableCircuits,
        sourceCircuits,
        loadCircuits,
        isAllSourceCircuitsSelected,
        isAllLoadCircuitsSelected,
      };
    }, [circuitDisplayConfigs, hiddenMeterIds]);

  const handleSelectAllMeters = useCallback(
    (isChecked: boolean) => {
      dispatch(
        setHiddenMeterIds(isChecked ? [] : Object.values(memoizedRawMeters).map(({ ww_device_id }) => ww_device_id))
      );
      dispatch(
        setCircuitDisplayConfigs(
          circuitDisplayConfigs.map((circuit) => {
            // when checked, if it is a load circuit, do not toggle it on
            if (isChecked && !circuit.isSourceCircuit) return circuit;
            return {
              ...circuit,
              isHidden: !isChecked,
            };
          })
        )
      );
    },
    [circuitDisplayConfigs, memoizedRawMeters]
  );

  const handleSelectAllCircuitsOfMeter = useCallback(
    (meterId: string, isChecked: boolean) => {
      dispatch(
        setHiddenMeterIds(isChecked ? hiddenMeterIds.filter((id) => id !== meterId) : [...hiddenMeterIds, meterId])
      );
      // When unchecked, hide all circuits of the meter
      // When checked, show all source circuits of the meter
      dispatch(
        setCircuitDisplayConfigs(
          circuitDisplayConfigs.map((circuit) => {
            if (circuit.meterId === meterId) {
              // when checked, if it is a load circuit, do not toggle it on
              if (isChecked && !circuit.isSourceCircuit) return circuit;
              return { ...circuit, isHidden: !isChecked };
            }
            return circuit;
          })
        )
      );
    },
    [circuitDisplayConfigs, hiddenMeterIds]
  );

  const handleSelectAllCircuitsOfType = useCallback(
    (isChecked: boolean, isSourceCircuit: boolean) => {
      dispatch(
        setCircuitDisplayConfigs(
          circuitDisplayConfigs.map((circuit) => {
            if (circuit.isSourceCircuit !== isSourceCircuit) return circuit;
            return {
              ...circuit,
              isHidden: !isChecked,
            };
          })
        )
      );
    },
    [circuitDisplayConfigs]
  );

  const handleSelectCircuit = useCallback(
    (circuitId: string, isChecked: boolean) => {
      dispatch(
        setCircuitDisplayConfigs(
          circuitDisplayConfigs.map((circuit) => {
            if (circuit.circuitId === circuitId) {
              return {
                ...circuit,
                isHidden: !isChecked,
              };
            }
            return circuit;
          })
        )
      );
    },
    [circuitDisplayConfigs]
  );

  return {
    availableCircuits,
    sourceCircuits,
    loadCircuits,
    isAllSourceCircuitsSelected,
    isAllLoadCircuitsSelected,
    handleSelectAllMeters,
    handleSelectAllCircuitsOfMeter,
    handleSelectAllCircuitsOfType,
    handleSelectCircuit,
    hiddenMeterIds,
    memoizedRawMeters,
  };
}
