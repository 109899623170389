import { createContext, useContext } from 'react';
import { Blocker } from 'react-router-dom';

// AlertDrawerContext
export type AlertDrawerContextProps = {
  onOpenAlertDrawer: (siteId?: number) => void;
  tenantAlertsCount: number;
  isAlertsLoaded: boolean;
};
export const AlertDrawerContext = createContext({} as AlertDrawerContextProps);

export const useAlertDrawerContext = () => useContext(AlertDrawerContext);

// BlockerContext
export type BlockerContextProps = {
  blocker?: Blocker;
  unBlock?: (blocker: Blocker) => void;
  disabled?: boolean;
};
export const BlockerContext = createContext({} as BlockerContextProps);

export const useBlockerContext = () => useContext(BlockerContext);

// SupportDrawerContext
export type SupportDrawerContextProps = {
  onOpenSupportDrawer: () => void;
};

export const SupportDrawerContext = createContext({} as SupportDrawerContextProps);

export const useSupportDrawerContext = () => useContext(SupportDrawerContext);

// SiteRouteChangeContext
export type SiteRouteChangeContextProps = {
  onMoveForward: (siteId?: number) => void;
};

export const SiteRouteChangeContext = createContext({} as SiteRouteChangeContextProps);

export const useSiteRouteChangeContext = () => useContext(SiteRouteChangeContext);
