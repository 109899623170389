import React, { useEffect } from 'react';
import { isEqual } from 'lodash';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';
import { COLOURS } from 'clipsal-cortex-utils/src/constants/colors';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BATTERY_CIRCUIT_TYPES,
  GRID_CIRCUIT_TYPES,
  HYBRID_CIRCUIT_TYPES,
  SOLAR_CIRCUIT_TYPES,
} from '../meter-setup-helpers';
import { selectRawMeters } from '../meterSetupSlice';
import { selectMeterConfiguration, setCircuitDisplayConfigs, setMemoizedRawMeters } from './meterConfigurationSlice';

// All the circuit types that are considered source circuits
const SOURCE_CIRCUIT_TYPES = [
  ...GRID_CIRCUIT_TYPES,
  ...SOLAR_CIRCUIT_TYPES,
  ...HYBRID_CIRCUIT_TYPES,
  ...BATTERY_CIRCUIT_TYPES,
];

/**
 * This component optimizes performance by reducing unnecessary re-renders caused by 5-second updates from meters.
 *
 * Context:
 * - Some sites have multiple meters, each with multiple circuits.
 * - And the chart seem to struggle with the growing number of circuits.
 * - Hence, we want re-render to occur only when actual changes are detected in meter data.
 *
 * Key Details:
 * - Each meter updates its `lastCommunicationTime` every 5 seconds to indicate it is still online. However, this update
 *   does not correspond to meaningful changes in meter or circuit data.
 * - Without optimization, every 5-second `lastCommunicationTime` update would trigger re-renders, even though the chart data remains unchanged.
 * - To address this, raw meter data is memoized, and the Redux store is updated only when significant changes (e.g.,
 *   circuit state or meter configuration updates) are detected. This minimizes unnecessary renders.
 * - Also charts do not rely on `lastCommunicationTime`, as meter configurations and chart-specific data are pulled independently.
 *
 * Why a Component, Not a Hook:
 * - Hooks trigger re-renders in the component they are invoked when state changes.
 * - Using a component isolates state changes, preventing parent component re-renders and improving performance.
 */
const SyncMeterState = () => {
  const rawMeters = useAppSelector(selectRawMeters);
  const { memoizedRawMeters } = useAppSelector(selectMeterConfiguration);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // set initial circuit display configs and raw meters
    dispatch(
      setCircuitDisplayConfigs(
        Object.values(rawMeters).flatMap((meter, meterIndex) =>
          meter.circuits.map(({ circuit_name, ww_circuit_id, clipsal_monitors }, circuitIndex) => {
            const isSourceCircuit = SOURCE_CIRCUIT_TYPES.includes(clipsal_monitors);

            // ensure the color index is within the bounds of the COLOURS array
            let colorIndex = meterIndex * 6 + circuitIndex;
            if (colorIndex >= COLOURS.length) colorIndex = colorIndex % COLOURS.length;

            return {
              meterId: meter.ww_device_id,
              circuitId: ww_circuit_id,
              label: `${circuit_name} (M${meterIndex + 1}, CH${circuitIndex + 1})`,
              color: COLOURS[colorIndex],
              isHidden: !isSourceCircuit,
              isSourceCircuit,
              meterIndex,
              circuitIndex,
            };
          })
        )
      )
    );
    dispatch(setMemoizedRawMeters(rawMeters));
  }, []);

  useEffect(() => {
    // diff the current state with the updated state
    // and update the redux store with the latest meter state when necessary
    if (Object.values(rawMeters).length) {
      const removeCommsFromMeters = (meters: Record<string, WattwatchersMeter>) => {
        return Object.values(meters).map((meter) => {
          // We ignore the comms field when comparing the meters as lastHeardAt is updated every 5s
          const { comms, ...rest } = meter;
          return rest;
        });
      };

      const hasChanged = !isEqual(removeCommsFromMeters(memoizedRawMeters), removeCommsFromMeters(rawMeters));
      if (hasChanged) dispatch(setMemoizedRawMeters(rawMeters));
    }
  }, [rawMeters]);

  return <></>;
};

export default SyncMeterState;
