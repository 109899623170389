import React from 'react';
import { Box, BoxProps, Center, Icon } from '@chakra-ui/react';
import { IoMdCheckmark } from 'react-icons/io';

type CustomCheckboxProps = {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  isDisabled?: boolean;
} & Omit<BoxProps, 'onChange' | 'isDisabled' | 'isChecked'>;

export default function CustomCheckbox({
  isChecked,
  isDisabled,
  onChange,
  children,
  ...boxProps
}: CustomCheckboxProps) {
  return (
    <Center
      w="fit-content"
      cursor={'pointer'}
      data-is-checked={isChecked}
      opacity={isDisabled ? 0.4 : 1}
      pointerEvents={isDisabled ? 'none' : 'initial'}
      justifyContent={'flex-start'}
      onClick={() => onChange(!isChecked)}
      {...boxProps}
    >
      <Center
        w={5}
        minW={5} // ensures the checkbox is always square
        h={5}
        mr={1}
        border="1px solid"
        borderRadius={4}
        borderColor={'gray.400'}
      >
        {isChecked ? <Icon as={IoMdCheckmark} /> : null}
      </Center>
      <Box>{children}</Box>
    </Center>
  );
}
