export const COLOURS = [
  '#2ab5ba',
  '#ff0000',
  '#e5a309',
  '#00bf03',
  '#f800df',
  '#0032e0',
  '#a567eb',
  '#cb5f2d',
  '#1a1a1a',
  '#ffcc00',
  '#005f73',
  '#e63946',
  '#457b9d',
  '#ff9f1c',
  '#264653',
  '#b3f591',
  '#d00000',
  '#4f772d',
  '#8338ec',
  '#3a86ff',
  '#bc4749',
  '#606c38',
  '#ff7b00',
  '#001219',
];
